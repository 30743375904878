<template>
  <v-container fluid grid-list-md align-center>
    <ComponentAdminEditPlant/>
  </v-container>
</template>

<script>
import ComponentAdminEditPlant from "@/components/admin/ComponentAdminEditPlant";
export default {
  components: {
    ComponentAdminEditPlant
  },
  methods:{
  }
};
</script>

<style scoped>
</style>