<template>
  <v-container grid-list-xs pt-0 pb-0>
    <v-card class="box-card pa-2">
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex
          xs12
          sm12
          md5
          d-flex
          offset-md2
        >
          <v-text-field
            label="หมายเลขการปลูกต้นไม้"
            prepend-inner-icon="info"
            solo
            hide-details
            color="blue"
            v-model="user_plant_id"
            @keyup.enter="queryPlantUser()"
          ></v-text-field>
        </v-flex>
        <v-flex
          xs12
          md2
        >
          <v-btn
            color="primary"
            @click="queryPlantUser()"
          >
            <v-icon class="mr-1">search</v-icon>
            ค้นหา
          </v-btn>
        </v-flex>
      </v-layout>

    </v-card>
    <div
      v-for="(i,index) in listUser"
      :key="index"
    >
    <v-card color="yellow" class="box-card pa-3 mt-2">
      <v-layout
        row
        wrap
      >
        <v-flex
          md12
          v-for="(u,index2) in i.user"
          :key="index2"
        >
          <div class="headline font-weight-bold">ข้อมูลผู้ปลูกต้นไม้</div>
          <div class="title ">
          <div>ชื่อ-นามสกุล : {{u.title}}{{u.name}} {{u.surname}}</div> 
          <div>เบอร์โทรศัพท์ : {{u.phone}} </div> 
          </div>
          
        </v-flex>
      </v-layout>
      </v-card>
      <!-- <v-card> -->
      <v-layout
        row
        wrap
        mt-2
      >
      <v-flex md12 sm12 class="headline font-weight-bold">ผลการค้นหา</v-flex>
        <v-flex md12>
          <template>
            <v-data-table
              :headers="headers"
              :items="i.search"
              hide-actions 
            >
              <template v-slot:items="props">
                <td>{{ props.index + 1 }}</td>
                <td>{{ props.item.user_plant_id }}</td>
                <td>{{ formatDate(props.item.date_planting) }}</td>
                <td>{{ props.item.plant_name }}</td>
                <td><v-chip :color="getColor(props.item.amount)" dark>{{ props.item.amount }}</v-chip></td>
                <td>{{ props.item.tambon_name }} {{ props.item.amphoe_name }} {{ props.item.province_name }}</td>
                <td v-if="props.item.note !== ''">{{ props.item.note }}</td>
                <td v-if="props.item.note === ''">-</td>
                <td>
                  <div v-for="(pic,index) in props.item.image" :key="index">
                  <a target="_blank" style="text-decoration:none" :href="'https://plant.forest.go.th/' + pic.images_original_link"><v-icon class="box-pic">photo</v-icon></a>
                  </div>
                  </td>
                <td class="justify-center layout px-0">
                  <v-icon
                    class="mr-2"
                    @click="editItem(props.item.date_planting, props.item.user_plant_id, props.item.plant_id, props.item.amount, props.item.note, props.item.plant_name, props.item.latitude, props.item.longitude)"
                  >
                    edit
                  </v-icon>
              </td>
              </template>
            </v-data-table>
          </template>
        </v-flex>
      </v-layout>
      <!-- </v-card> -->
      <v-layout
        row
        wrap
        mt-2
      >
      <v-flex md12 sm12 class="title font-weight-bold">ผลการค้นหาอื่นๆ</v-flex>
        <v-flex md12>
          <template>
            <v-data-table
              :headers="headers"
              :items="i.plant"
            >
              <template v-slot:items="props">
                <td>{{ props.index + 1 }}</td>
                <td>{{ props.item.user_plant_id }}</td>
                <td>{{ props.item.date_planting }}</td>
                <td>{{ props.item.plant_name }}</td>
                <td><v-chip :color="getColor(props.item.amount)" dark>{{ props.item.amount }}</v-chip></td>
                <td>{{ props.item.tambon_name }} {{ props.item.amphoe_name }} {{ props.item.province_name }}</td>
                <td v-if="props.item.note !== ''">{{ props.item.note }}</td>
                <td v-if="props.item.note === ''">-</td>
                <td>
                  <div v-for="(pic,index) in props.item.image" :key="index">
                  <a target="_blank" style="text-decoration:none" :href="'https://plant.forest.go.th/' + pic.images_original_link"><v-icon class="box-pic">photo</v-icon></a>
                  </div>
                  </td>
                <td class="justify-center layout px-0">
                  <v-icon
                    class="mr-2"
                    @click="editItem(props.item.date_planting, props.item.user_plant_id, props.item.plant_id, props.item.amount, props.item.note, props.item.plant_name, props.item.latitude, props.item.longitude)"
                  >
                    edit
                  </v-icon>
              </td>
              </template>
            </v-data-table>
          </template>
        </v-flex>
      </v-layout>
    </div>
    <AdminModalEditTree ref="AdminModalEditTree" @fetch="queryPlantUser()"></AdminModalEditTree>
  </v-container>
</template>

<script>
import AdminModalEditTree from '@/components/admin/ComponentAdminModalEditTree'
export default {
  components:{
    AdminModalEditTree,
  },
  data() {
    return {
      user_plant_id: '',
      listUser: [],
      headers: [
        { text: "No.", value: "no" },
        { text: "รหัสการปลูก", value: "plant_id" },
        { text: "วันที่ปลูก", value: "date_planting" },
        { text: "ชนิดไม้", value: "plant_name" },
        { text: "จำนวน", value: "amount" },
        { text: "ปลูกที่", value: "" },
        { text: "เพิ่มเติม", value: "option" },
        { text: "รูป", value: "image" },
        { text: "", value: "option" },
        
      ]
    };
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      var year_thai = parseInt(year) + 543
      return `${day}-${month}-${year_thai}`
    },
    editItem (date, user_plant_id, plant_id ,amount, note, plant_name, latitude, longitude) {
      this.$refs.AdminModalEditTree.openDialog(date, user_plant_id, plant_id ,amount, note, plant_name, latitude, longitude)
    },
    getColor (amount) {
        if (amount >= 1000) return 'red'
        else return 'green'
    },
    queryPlantUser() {
      if (this.user_plant_id === "") {
        this.$swal("กรุณากรอกหมายเลขการปลูกต้นไม้", "", "error");
      } else {
        this.axios
          .post(process.env.VUE_APP_IPFLASK + "/queryPlantUserForAdmin", {
            user_plant_id: this.user_plant_id
          })
          .then(response => {
            // console.log(response.data);
            if (response.data !== 'not found user_plant_id'){
              this.listUser = response.data;
            } else{
              this.$swal("ไม่พบรหัสการปลูกต้นไม้นี้", "กรุณาตรวจสอบอีกครั้ง", "error")
            }
            
            // this.user_plant_id = "";
          });
      }
    }
  }
};
</script>

<style scoped>
.box-card {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  box-shadow: none !important;
}

.box-pic{
  background: white;
    text-align: center;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
}
</style>