import { render, staticRenderFns } from "./ComponentAdminEditPlant.vue?vue&type=template&id=e6abc3e4&scoped=true&"
import script from "./ComponentAdminEditPlant.vue?vue&type=script&lang=js&"
export * from "./ComponentAdminEditPlant.vue?vue&type=script&lang=js&"
import style0 from "./ComponentAdminEditPlant.vue?vue&type=style&index=0&id=e6abc3e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6abc3e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/builds/rfd/seeding_72/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VChip,VContainer,VDataTable,VFlex,VIcon,VLayout,VTextField})
