const createURL = (path) => process.env.VUE_APP_IPFLASK + "/" + path;

const handleAjaxError = (error, context) => {
  // console.log(error)
  let title = "เกิดข้อผิดพลาดขึ้น";
  let content = "";
  if (!error.response) {
    // do nothing
  } else if (error.response.status == 413) {
    // images too large
    title = "ไฟล์รูปภาพของท่านมีขนาดใหญ่เกินไป";
    content = "กรุณาอัพโหลดไฟล์รูปภาพครั้งละไม่เกิน 100MB";
    context.$swal(title, content, "error");
    context.loading_save = false;
  } else if ("data" in error.response) {
    // other error, try to get error message
    const html = document.createElement("html");
    html.innerHTML = error.response.data;
    const titleTag = html.getElementsByTagName("title");
    if (titleTag.length == 1) {
      content = titleTag[0].innerText;
    }
  }
  context.$swal(title, content, "error");
  context.loading_save = false;
};

export { createURL, handleAjaxError };
