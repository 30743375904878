<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent scrollable max-width="500">
      <v-card>
        <v-toolbar dark color="primary" height="45">
          <v-toolbar-title class="black--text w-100 ta-c headline font-weight-bold">บันทึกการปลูกต้นไม้</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-container grid-list-md pa-0 class="modal_loading" v-if="loading_save">
            </v-container>
            <v-container grid-list-md pa-0>
              <v-layout row wrap justify-center >
                <v-flex xs12 sm12 md12>
                  <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  color="blue"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="dateFormatted"
                    readonly
                    prepend-inner-icon="event"
                    label="วันที่ปลูก*"
                    solo
                    hide-details
                    v-on="on"
                    color="blue"
                    @blur="date = parseDate(dateFormatted)"
                    :rules="nameRules"
                    required
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="th" v-model="date" no-title @input="menu = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
           <v-layout row wrap justify-center mt-2>
             <v-flex md12 sm12>
               <span style="font-size: 20px;" class="font-weight-bold">รายละเอียดต้นไม้</span>
             </v-flex>
             <v-layout row wrap v-for="(i,index) in plantInformation" :key="index">
               <v-flex md12>
               <v-card class="box-card">
                 <v-container grid-list-xs pa-2>
                 <v-layout row wrap>
               <v-flex xs12 md12>
               <div style="display:flex;align-items:center;">
                 <span class="title ml-1 font-weight-bold">ชนิดที่ {{index+1}}</span>
                 <v-btn @click="removeComponent(index)" small color="error" icon class="mt-0" v-if="plantInformation.length !== 1">
                  <v-icon dark>remove</v-icon>
                </v-btn>
               </div>
               </v-flex>
               <!-- <pre>{{plantInformation}}</pre> -->
              <v-flex xs12 sm12 md6>
                <v-autocomplete
                :items="computedItems"
                label="พิมพ์ชื่อต้นไม้*"
                v-model="i.plant_id"
                solo
                item-text="plant_name"
                item-value="plant_id"
                hide-details
                hide-selected
                persistent-hint
                color="blue"
                :rules="nameRules"
                required
                return-object
                :menu-props="menuProps"
                @change="disabledItem()"
                >
                <template v-slot:no-data >
                  <v-list-tile @click="clickOther()">
                    <v-list-tile-title>
                      ไม้ยืนต้นอื่นๆ
                    </v-list-tile-title>
                  </v-list-tile>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field
              label="จำนวน*"
              solo
              hide-details
              suffix="ต้น"
              color="blue"
              :mask="mask"
              v-model="i.amount"
              :rules="nameRules"
              required
              ref="amount"
              ></v-text-field>
            </v-flex>
             <v-flex xs12 md12 style="max-width:100%">
              <div style="max-width:100%;" class="pointer">
                <v-layout row wrap>
                  <v-flex xs12 md12 class="ta-c" v-if="index === 0">
                    <div outline class="border-upload-disabled upload_tree" block style="height: 80px; white-space: unset !important; overflow-y: auto;">
                      <input ref="image_input_upload" type="file" multiple @change="onFileChange($event, index)" style="display: none" accept="image/*"/>
                      <div v-if="i.attachment.length == []"><v-icon class="mr-1">camera_alt</v-icon><span class="title">อัพโหลดรูปต้นไม้*</span></div>
                      <div v-else style="max-height:60px;">
                        <span v-for="(att,index2) in i.attachment" :key="index2">
                          <v-chip @input="remove(att.name, index)" style="cursor:auto !important">
                            <v-flex>{{att.name}}</v-flex>
                          </v-chip>
                        </span>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs12 md12 class="ta-c" v-if="index !== 0">
                    <div outline :class="[i.check ? 'border-upload-warning' : 'border-upload']" block @click="pickFile(index)" style="height: 80px; white-space: unset !important; overflow-y: auto;" class="upload_tree">
                      <input ref="image_input_upload" type="file" multiple @change="onFileChange($event, index)" style="display: none" accept="image/*"/>
                      <div v-if="i.attachment.length == []"><v-icon class="mr-1">camera_alt</v-icon><span class="title">อัพโหลดรูปต้นไม้*</span></div>
                      <div v-else style="max-height:60px;">
                        <span v-for="(att,index2) in i.attachment" :key="index2">
                          <v-chip close @input="remove(att.name, index)">
                            <v-flex>{{att.name}}</v-flex>
                          </v-chip>
                        </span>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex> 
             </v-layout>
             </v-container>
            </v-card>
            </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-between mt-2 ml-2>
              <v-flex md8>
                <span class="title">*สามารถเพิ่มชนิดไม้ได้ครั้งละไม่เกิน 10 ชนิดต่อการบันทึก 1 ครั้ง</span>
              </v-flex>
              <v-flex md4 justify-end style="display:flex">
                <v-btn @click="addComponent()" round color="primary"  class="mt-0" :disabled="disabled_add ? true : false">
                <v-icon dark>add</v-icon>เพิ่มชนิดไม้
              </v-btn>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-container>
      </v-form>
      <v-layout row wrap>
        <span style="font-size: 20px;" class="font-weight-bold">รายละเอียดเพิ่มเติม</span>
        <v-flex xs12 md12>
          <v-text-field
          label="บันทึก"
          v-model="note"
          solo
          hide-details
          :disabled="loading ? true : false"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="ta-c jc-c">
      <v-btn color="green darken-1" flat @click="dialog = false">ยกเลิก</v-btn>
      <v-btn color="primary" round @click="submit()" :disabled="loading ? true : false" :loading="loading_save">บันทึก</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</v-layout>
</template>

<script>
var _ = require('lodash')
// import { Decode } from '@/services'
import { handleAjaxError } from '@/utils/common'
export default {
  data () {
    return {
      disabled: false,
      disabled_add: false,
      dialog: false,
      dateFormatted: '',
      date: '',
      menu: false,
      imageName: '',
      listDepartment: [],
      treeFromDepartment: '',
      placetoSeed: 'latlong',
      hasImage: false,
      image: null,
      mask: '#####',
      province_id: '',
      province_id_new: '',
      amphoe_id: '',
      amphoe_id_new: '',
      tambon_id: '',
      tambon_id_new: '',
      province_name: '',
      amphoe_name: '',
      tambon_name: '',
      attachment: [],
      listPlant: [],
      otherListPlant: [
        {
          plant_name: 'อื่นๆ',
          plant_id: 126
        }
      ],
      listProvince: [],
      listAmphoe: [],
      listTambon: [],
      latitude: '',
      latitude_new: '',
      longitude: '',
      longitude_new: '',
      plant_id: '',
      other: '',
      loading: false,
      amount: '',
      card_id: '',
      note: '',
      loading_save: false,
      nameRules: [
        v => !!v || 'Name is required'
      ],
      user_plant_id: '',
      listLatLng: '',
      check: false,
      menuProps:{
        "closeOnClick":false,
        "closeOnContentClick":true
        },
        plantInformation: [
          {
          plant_id: '',
          amount: '',
          attachment: [],
          check: false
          }
      ],
      disabledItems: [],
    }
  },
  computed: {
    computedItems() {
      return this.listPlant.map(item => {
        return {
          plant_id: item.plant_id, 
          plant_name: item.plant_name, 
          disabled: this.disabledItems.includes(item.plant_id)
        }
      })
    }
  },
  created(){
    this.queryDepartment()
    this.queryPlant()
    this.queryProvince()
  },
  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
    placetoSeed(){
      if (this.placetoSeed === 'address'){
        this.province_id = this.province_id_new
        this.queryAmphoe(this.province_id.province_id)
        this.amphoe_id = this.amphoe_id_new
        this.queryTambon(this.amphoe_id.amphoe_id)
        this.tambon_id = this.tambon_id_new
      } else {
        this.latitude = this.latitude_new
        this.longitude = this.longitude_new
        this.loading = false
      }
    },
    treeFromDepartment(){
      if (this.treeFromDepartment !== 4){
        this.other = ''
      }
    },
    plantInformation(){
      if(this.plantInformation.length === 10){
        this.disabled_add = true
      } else {
        this.disabled_add = false
      }
    }
  },
  methods: {
    addComponent(){
      this.disabledItem()
      this.plantInformation.push({
          plant_id: '',
          amount: '',
          attachment: [],
          check: ''
          })
      this.check = false
    },
    removeComponent(index){
      this.plantInformation.splice(index, 1)
    },
    disabledItem(){
      this.disabledItems = []
      _.forEach(this.plantInformation, (p) => {
        this.disabledItems.push(p.plant_id.plant_id)
      })
    },
    clickOther(){
      this.plant_id = ''
      this.$refs.form.resetValidation()
      this.plant_id = 126
      // setTimeout(() => this.plant_id = 126, 10)
    },
    queryDepartment(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryDepartment').then((response) => {
        this.listDepartment = response.data
      })
    },
    queryPlant(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryPlant').then((response) => {
        this.listPlant = response.data
      })
    },
    queryProvince(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryProvince').then((response) => {
        this.listProvince = response.data
      })
    },
    queryAmphoe(){
      this.tambon_id = ''
      this.amphoe_id = ''
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryAmphoe', {province_id: this.province_id.province_id}).then((response) => {
        this.listAmphoe = response.data
      })
    },
    queryTambon(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryTambon', {amphoe_id: this.amphoe_id.amphoe_id}).then((response) => {
        this.listTambon = response.data
      })
    },
    onFileChange(event, index) {
      let e = event;
      let files = e.target.files;
      if (!files.length)
      return;

      // console.log(files)
      let new_files = _.filter(files, (file) => {
        var duplicate = false
        _.each(this.plantInformation[index].attachment, (plant) => {
          if (plant.name == file.name) {
            duplicate = true
          }
        })
        if (duplicate) {
          return false
        }
        return true
      })
      // console.log(new_files)
      this.createImage(new_files, index);
      this.$refs['image_input_upload'][index].value = ''
    },
    pickFile (index) {
      this.$refs['image_input_upload'][index].click()
    },
    createImage(files, index) {
      for (var i=0; i<files.length; i++) {
        this.plantInformation[index].attachment.push({ file: files[i], no: this.plantInformation[index].attachment.length, type:files[i].type, name:files[i].name});
      }
      this.plantInformation[index].check = false
    },
    remove(name, index) {
      this.plantInformation[index].attachment.splice(_.findIndex(this.plantInformation[index].attachment, {name}), 1)
    },
    openDialog(date, user_plant_id, plant_id ,amount, note, plant_name, latitude, longitude){
      this.dialog = true
      this.date = date
      this.loading_save = false
      this.$refs.form.resetValidation()
      this.note = note
      this.user_plant_id = user_plant_id
      this.check = false
      this.loading = false
      this.latitude = latitude
      this.longitude = longitude
      this.plantInformation = [{
        'plant_id': {
            "plant_id": plant_id,
            "plant_name": plant_name,
            "disabled": false
        },
          amount: amount,
          attachment: [{
            name: 'default_image.jpg'
          }],
          check: false
      }]
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      var year_thai = parseInt(year) + 543
      return `${day}/${month}/${year_thai}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      var year_2 = parseInt(year) - 543
      return `${year_2}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    submit(){
      this.loading_save = true
      var isCheckPic = true
      _.each(this.plantInformation, (plant) => {
          if(plant.attachment.length === 0){
             plant.check = true
             this.$swal('กรุณาอัพโหลดภาพที่ถ่ายกับต้นไม้ของท่าน','','error');
             isCheckPic = false
             this.loading_save = false
          }
      })
      if (this.$refs.form.validate() && isCheckPic) {
          if (this.placetoSeed === 'address'){
            this.latitude = this.tambon_id.latitude
            this.longitude = this.tambon_id.longitude
          }
          var formData = new FormData()
          formData.append('user_plant_id', this.user_plant_id)
          this.plantInformation.forEach((attach, i) => {
              formData.append('plantInformation', JSON.stringify(this.plantInformation))
            attach.attachment.forEach((a) => {
              formData.append('plantInformationImage['+ i +']', a.file)
            })
          })
            
          // for (var pair of formData.entries()) {
          //   console.log(pair[0]+ ', ' + pair[1]);
          // }
          // console.log(this.plantInformation);
          this.axios.post(process.env.VUE_APP_IPFLASK + '/editPlantByAdmin', formData).then((response) => {
            if (response.data === 'Insert Success') {
              this.dialog = false
              this.$swal('บันทึกการปลูกต้นไม้เรียบร้อย','','success');
              this.date = ''
              this.plant_id = ''
              this.amount = ''
              this.province_id = ''
              this.amphoe_id = ''
              this.tambon_id = ''
              this.latitude = ''
              this.longitude = ''
              this.treeFromDepartment = ''
              this.plantInformation = [
                {
                plant_id: '',
                amount: '',
                attachment: [],
                check: false
                }
              ]
              this.disabledItems = []
              this.placetoSeed = 'latlong'
              this.other = ''
              this.note = ''
              this.loading_save = false
              this.$emit('fetch', true)
            }
          })
          .catch(error => handleAjaxError(error, this))
      } else {
        this.$swal('กรุณากรอกข้อมูลให้ครบถ้วน','','error');
        // this.check = true
        this.loading_save = false
      }
    }
  }
}
</script>

<style scoped>
.border-upload{
  border: 2px solid #C4C4C4;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.border-upload-disabled{
  border: 2px solid #C4C4C4;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: auto;
}
.border-upload-warning{
  border: 2px solid red;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 5px;
}


#upload_tree .v-btn--active:before, .v-btn:hover:before, .v-btn:focus:before{
  background-color: transparent !important;
}

.modal_loading{
  position: absolute;
  background: #4e4e4e70;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}



</style>
